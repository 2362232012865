import Vue from 'vue'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://unsapi.easybew.com/',
  // timeout: 3000,
  // type: 'POST',
  // headers: {
  // 'X-CSRF-TOKEN': "bHPQECSmJ1ZbvhEfNyL2evn7exlb6LXqEqgLorw1"
  // },
})

Vue.prototype.$http = axiosIns

export default axiosIns